<template>
  <div v-if="galleryItems.length > 0" ref="vueSwiper" class="swiper mb-8" :data-options="swiperOptions()">
    <div class="swiper-wrapper mb-5">
      <div v-for="(item, idx) in galleryItems" :key="idx" class="swiper-slide self-center">
        <template v-if="item.type == 'video'">
          <video v-if="showVideo" ref="video" class="w-full h-auto" controls>
            <source :src="item.src" :type="item.mimeType" />
          </video>
          <div v-else :style="item.style" class="aspect-5/4 w-full h-full flex items-center justify-center">
            <div
              class="z-10 absolute bg-white flex flex-col gap-y-1 items-center justify-center w-14 h-14 md:w-20 md:h-20 cursor-pointer"
              @click="playVideo"
            >
              <KmIcon class="h-4 w-4" name="play" />
              <div class="typeset-8 md:block hidden">Play</div>
            </div>
          </div>
        </template>

        <template v-if="item.type == 'inline-video'">
          <video v-if="showVideo" ref="video" controls>
            <source :src="item.src" :type="item.mimeType" />
          </video>
          <div v-else :style="item.style" class="aspect-5/4 w-full h-full flex items-center justify-center">
            <div
              class="z-10 absolute bg-white flex flex-col gap-y-1 items-center justify-center w-14 h-14 md:w-20 md:h-20 cursor-pointer"
              @click="playVideo"
            >
              <KmIcon class="h-4 w-4" name="play" />
              <div class="typeset-8 md:block hidden">Play</div>
            </div>
          </div>
        </template>

        <a v-if="item.type == 'image'" :href="item.src" data-fancybox>
          <img :src="item.src" :alt="item.caption" :title="item.caption" class="border border-grey/80" />
        </a>
      </div>
    </div>

    <div class="flex justify-between items-center">
      <div class="swiper-buttons gap-x-2">
        <div class="swiper-button-prev"><KmIcon name="angle" class="rotate-180" /></div>
        <div class="swiper-button-next"><KmIcon name="angle" /></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { nextTick, onUpdated, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { uniqBy } from 'lodash-es'
import { useProductStore } from './stores/useProductStore'
import KmIcon from './components/general/KmIcon.vue'
import setupSwiper from '../../utils/swiper'

const { artwork, activeProduct } = storeToRefs(useProductStore())
const { setProductGalleryItems } = useProductStore()
const galleryItems = ref([])
const showVideo = ref(false)
const video = ref(null)
const vueSwiper = ref(null)

onUpdated(() => {
  if (vueSwiper.value) {
    setupSwiper(vueSwiper.value)
  }
})

function activeProductImageUrl() {
  return {
    type: 'image',
    src: activeProduct.value.image_url,
    featured: false
  }
}

function swiperOptions() {
  return JSON.stringify({
    slidesPerGroup: 1,
    slidesPerView: 1,
    breakpoints: {
      389: {
        slidesPerGroup: 1,
        slidesPerView: 1,
        spaceBetween: 10
      },
      767: {
        slidesPerGroup: 1,
        slidesPerView: 1,
        spaceBetween: 10
      },
      1023: {
        slidesPerGroup: 1,
        slidesPerView: 1,
        spaceBetween: 10
      }
    }
  })
}

watch(activeProduct, () => {
  const items = setProductGalleryItems(artwork.value, activeProduct.value)
  const featuredIndex = items.findIndex((item) => item.featured === true)

  if (featuredIndex > -1) {
    const featuredItem = items.splice(featuredIndex, 1)[0]
    items.unshift(featuredItem)
  } else {
    items.unshift(activeProductImageUrl())
  }

  galleryItems.value = uniqBy(items, 'src')
})

function playVideo() {
  showVideo.value = true

  nextTick(() => {
    // NB: `video` is treated as an array because it appears in a loop
    const videoEl = video.value[0]
    videoEl.addEventListener('ended', (_event) => {
      window.setTimeout(() => {
        showVideo.value = false
      }, 1000)
    })

    videoEl.play()
  })
}
</script>
