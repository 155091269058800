import { createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["data-options"]
const _hoisted_2 = { class: "flex justify-between mb-8 items-center" }
const _hoisted_3 = { class: "swiper-buttons" }
const _hoisted_4 = { class: "swiper-button-prev" }
const _hoisted_5 = { class: "swiper-button-next" }
const _hoisted_6 = { class: "swiper-wrapper" }
const _hoisted_7 = ["src", "type"]
const _hoisted_8 = ["src", "type"]
const _hoisted_9 = ["href"]
const _hoisted_10 = ["src", "alt", "title"]
const _hoisted_11 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($setup.galleryItems.length > 0)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        ref: "vueSwiper",
        class: "swiper mb-12 md:mb-24",
        "data-options": $setup.swiperConfig()
      }, [
        _createElementVNode("div", _hoisted_2, [
          _cache[0] || (_cache[0] = _createElementVNode("h2", { class: "mb-4" }, "Gallery", -1)),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode($setup["KmIcon"], {
                name: "angle",
                class: "rotate-180"
              })
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode($setup["KmIcon"], { name: "angle" })
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.galleryItems, (item, idx) => {
            return (_openBlock(), _createElementBlock("div", {
              key: idx,
              class: _normalizeClass(["swiper-slide col-width-4", item.type == 'video' ? 'self-end' : ''])
            }, [
              (item.type == 'video')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    ($setup.showVideo)
                      ? (_openBlock(), _createElementBlock("video", {
                          key: 0,
                          ref_for: true,
                          ref: "video",
                          class: "w-full h-auto",
                          controls: ""
                        }, [
                          _createElementVNode("source", {
                            src: item.src,
                            type: item.mimeType
                          }, null, 8, _hoisted_7)
                        ], 512))
                      : (_openBlock(), _createElementBlock("div", {
                          key: 1,
                          style: _normalizeStyle(item.style),
                          class: "aspect-5/4 w-full h-full flex items-center justify-center"
                        }, [
                          _createElementVNode("div", {
                            class: "z-10 absolute bg-white flex flex-col gap-y-1 items-center justify-center w-14 h-14 md:w-20 md:h-20 cursor-pointer",
                            onClick: $setup.playVideo
                          }, [
                            _createVNode($setup["KmIcon"], {
                              class: "h-4 w-4",
                              name: "play"
                            }),
                            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "typeset-8 md:block hidden" }, "Play", -1))
                          ])
                        ], 4))
                  ], 64))
                : _createCommentVNode("", true),
              (item.type == 'inline-video')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    ($setup.showVideo)
                      ? (_openBlock(), _createElementBlock("video", {
                          key: 0,
                          ref_for: true,
                          ref: "video",
                          controls: ""
                        }, [
                          _createElementVNode("source", {
                            src: item.src,
                            type: item.mimeType
                          }, null, 8, _hoisted_8)
                        ], 512))
                      : (_openBlock(), _createElementBlock("div", {
                          key: 1,
                          style: _normalizeStyle(item.style),
                          class: "aspect-5/4 w-full h-full flex items-center justify-center"
                        }, [
                          _createElementVNode("div", {
                            class: "z-10 absolute bg-white flex flex-col gap-y-1 items-center justify-center w-14 h-14 md:w-20 md:h-20 cursor-pointer",
                            onClick: $setup.playVideo
                          }, [
                            _createVNode($setup["KmIcon"], {
                              class: "h-4 w-4",
                              name: "play"
                            }),
                            _cache[2] || (_cache[2] = _createElementVNode("div", { class: "typeset-8 md:block hidden" }, "Play", -1))
                          ])
                        ], 4))
                  ], 64))
                : _createCommentVNode("", true),
              (item.type == 'image')
                ? (_openBlock(), _createElementBlock("a", {
                    key: 2,
                    href: item.src,
                    "data-fancybox": "",
                    "data-touch": "false"
                  }, [
                    _createElementVNode("img", {
                      src: item.src,
                      alt: item.caption,
                      title: item.caption,
                      class: "border border-grey/80"
                    }, null, 8, _hoisted_10)
                  ], 8, _hoisted_9))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                class: "typeset-8 mt-2 min-h-[36px] h-[36px] text-format",
                innerHTML: item.caption
              }, null, 8, _hoisted_11)
            ], 2))
          }), 128))
        ])
      ], 8, _hoisted_1))
    : _createCommentVNode("", true)
}