<template>
  <!-- TOP ROW -->
  <div class="grid" :style="gridStyle()">
    <div>
      <div class="flex justify-end items-center h-full">
        <div>{{ productHeightCm() }}cm</div>
        <div class="h-full border-t border-b w-4 min-w-4 flex flex-row divide-x mr-4">
          <div class="w-1/2"><!-- . --></div>
          <div class="w-1/2"><!-- . --></div>
        </div>
      </div>
    </div>
    <div>
      <img
        :src="src"
        :style="{ width: `${widthPx}px` }"
        class="cursor-pointer shadow-md border border-grey/80"
        @click="productStore.toggleViewInARoom()"
      />
    </div>
    <div><!-- . --></div>
  </div>

  <!-- BOTTOM ROW -->
  <div class="grid" :style="gridStyle()">
    <div><!-- . --></div>
    <div :style="{ width: `${widthPx}px` }" class="mx-auto text-center">
      <div class="border-l border-r h-4 flex flex-col divide-y mt-4">
        <div class="h-1/2"><!-- . --></div>
        <div class="h-1/2"><!-- . --></div>
      </div>
      {{ productWidthCm() }}cm
    </div>
    <div><!-- . --></div>
  </div>

  <div class="flex justify-end mx-5 mb-5">
    <div class="w-[102px] lg:w-[170px] text-center">
      <div id="chair" v-html="chair"></div>
      <div class="border-l border-r h-4 flex flex-col divide-y">
        <div class="h-1/2"><!-- . --></div>
        <div class="h-1/2"><!-- . --></div>
      </div>
      50cm
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, onUpdated, ref } from 'vue'
import { useProductStore } from '../../stores/useProductStore'
import chair from '../../../../../../assets/svg/chair.svg'
import { storeToRefs } from 'pinia'

const productStore = useProductStore()
const widthPx = ref(0)
const { activeProduct } = storeToRefs(productStore)

defineProps<{
  src: string
}>()

onMounted(() => {
  resizeImage()
  window.addEventListener('resize', resizeImage)
})

onUpdated(() => {
  resizeImage()
})

function productHeight() {
  return activeProduct?.value?.dimensions?.height
}

function productWidth() {
  return activeProduct?.value?.dimensions?.width
}

function productHeightCm() {
  return Math.round(productHeight() / 10)
}

function productWidthCm() {
  return Math.round(productWidth() / 10)
}

function gridStyle() {
  return {
    'grid-template-columns': `minmax(60px, 1fr) ${widthPx.value}px minmax(60px, 1fr)`
  }
}

function resizeImage() {
  const chairWidthMm = 500
  const element = document.querySelector('#chair path')
  // TODO: there seems to be a bug with mobile Safari that prevents this element from being found
  // But we've not been able to reproduce it
  if (element !== null) {
    const positionInfo = element.getBoundingClientRect()
    const chairWidthPx = positionInfo.width

    const productWidthMm = productWidth()
    const pixelsPerMm = chairWidthPx / chairWidthMm
    widthPx.value = pixelsPerMm * productWidthMm
  }
}
</script>
