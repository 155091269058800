<template>
  <div>
    <KmCartStatus :item-count="numberOfCartItems" :loading="loading" @clicked="cartClicked" />
  </div>

  <KmMiniCart :visible="miniCartVisible" @close-cart="toggleMiniCartVisibility"></KmMiniCart>

  <div v-show="miniCartVisible" class="modal-bg" @click.prevent="toggleMiniCartVisibility"></div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { useCartStore } from './stores/useCartStore'
import KmCartStatus from './components/cart/KmCartStatus.vue'
import KmMiniCart from './components/cart/KmMiniCart.vue'

import { onMounted } from 'vue'
const { fetchCart, toggleMiniCartVisibility } = useCartStore()
const { loading, numberOfCartItems, miniCartVisible } = storeToRefs(useCartStore())

function cartClicked() {
  toggleMiniCartVisibility()
}

onMounted(() => {
  window.addEventListener('itemAddedToCart', () => {
    fetchCart().finally(() => {
      window.scrollTo(0, 0)
      miniCartVisible.value = true
    })
  })
})
</script>
