import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-end items-center h-full" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "flex justify-end mx-5 mb-5" }
const _hoisted_4 = { class: "w-[102px] lg:w-[170px] text-center" }
const _hoisted_5 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "grid",
      style: _normalizeStyle($setup.gridStyle())
    }, [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", null, _toDisplayString($setup.productHeightCm()) + "cm", 1),
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "h-full border-t border-b w-4 min-w-4 flex flex-row divide-x mr-4" }, [
            _createElementVNode("div", { class: "w-1/2" }),
            _createElementVNode("div", { class: "w-1/2" })
          ], -1))
        ])
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("img", {
          src: $props.src,
          style: _normalizeStyle({ width: `${$setup.widthPx}px` }),
          class: "cursor-pointer shadow-md border border-grey/80",
          onClick: _cache[0] || (_cache[0] = $event => ($setup.productStore.toggleViewInARoom()))
        }, null, 12, _hoisted_2)
      ]),
      _cache[2] || (_cache[2] = _createElementVNode("div", null, null, -1))
    ], 4),
    _createElementVNode("div", {
      class: "grid",
      style: _normalizeStyle($setup.gridStyle())
    }, [
      _cache[4] || (_cache[4] = _createElementVNode("div", null, null, -1)),
      _createElementVNode("div", {
        style: _normalizeStyle({ width: `${$setup.widthPx}px` }),
        class: "mx-auto text-center"
      }, [
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "border-l border-r h-4 flex flex-col divide-y mt-4" }, [
          _createElementVNode("div", { class: "h-1/2" }),
          _createElementVNode("div", { class: "h-1/2" })
        ], -1)),
        _createTextVNode(" " + _toDisplayString($setup.productWidthCm()) + "cm ", 1)
      ], 4),
      _cache[5] || (_cache[5] = _createElementVNode("div", null, null, -1))
    ], 4),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          id: "chair",
          innerHTML: $setup.chair
        }, null, 8, _hoisted_5),
        _cache[6] || (_cache[6] = _createElementVNode("div", { class: "border-l border-r h-4 flex flex-col divide-y" }, [
          _createElementVNode("div", { class: "h-1/2" }),
          _createElementVNode("div", { class: "h-1/2" })
        ], -1)),
        _cache[7] || (_cache[7] = _createTextVNode(" 50cm "))
      ])
    ])
  ], 64))
}